import { Base64 } from 'js-base64'
import request from '@/utils/request'

export const GetData = function (data) {// 获取单条数据
    return request({
        url: "/API/Forum/GetData",
        method: 'GET',
        params:data,
    })
}

export const GetList = function (data) {// 获取列表
    return request({
        url: "/API/Forum/GetList",
        method: 'GET',
        params:data,
    })
}

export const GetListById = function (data) {// 根据帖子获取所有回复列表
    return request({
        url: "/API/Forum/GetListById",
        method: 'GET',
        params:data,
    })
}

export const GetUserList = function (data) {// 获取发帖、积分排行
    return request({
        url: "/API/Forum/GetUserList",
        method: 'GET',
        params:data,
    })
}


export const Up = function (data) {// 支持
    return request({
        url: "/API/Forum/Up",
        method: 'GET',
        params:data,
    })
}

export const Down = function (data) {// 反对
    return request({
        url: "/API/Forum/Down",
        method: 'GET',
        params:data,
    })
}

export const Del = function (data) {// 删除
    return request({
        url: "/API/Forum/Del",
        method: 'GET',
        params:data,
    })
}

export const Good = function (data) {// 管理员 - 帖子 加精
    return request({
        url: "/API/Forum/Good",
        method: 'GET',
        params:data,
    })
}

export const Hot = function (data) {// 管理员 - 帖子 设置热门
    return request({
        url: "/API/Forum/Hot",
        method: 'GET',
        params:data,
    })
}

export const Add = function (data) {// 发帖
    return request({
        url: "/API/Forum/Add",
        method: 'POST',
        data:Base64.encode(JSON.stringify(data)),
        headers: {
            'Content-Type': 'application/json'
        },
    })
}

export const Update = function (data) {// 修改帖子
    return request({
        url: "/API/Forum/Update",
        method: 'POST',
        data:Base64.encode(JSON.stringify(data)),
        headers: {
            'Content-Type': 'application/json'
        },
    })
}

export const Reply = function (data) {// 回复帖子
    return request({
        url: "/API/Forum/Reply",
        method: 'POST',
        data:Base64.encode(JSON.stringify(data)),
        headers: {
            'Content-Type': 'application/json'
        },
    })
}
export const GetAuditList = function (data) {// 获取待审核帖子列表
    return request({
        url: "/API/Forum/GetAuditList",
        method: 'GET',
        params:data,
    })
}

export const Audit = function (data) {// 管理员 - 审核帖子
    return request({
        url: "/API/Forum/Audit",
        method: 'GET',
        params:data,
    })
}