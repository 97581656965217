import request from '@/utils/request'
import { Base64 } from 'js-base64'

export const GetData = function (data) {// 获取单条数据
    return request({
        method: 'GET',
        url: '/API/Comment/GetData',
        params:data,
    })
}

export const GetList = function (data) {// 获取评论列表
    return request({
        method: 'GET',
        url: '/API/Comment/GetList',
        params:data,
    })
}

export const GetListById = function (data) {// 获取评论列表
    return request({
        method: 'GET',
        url: '/API/Comment/GetListById',
        params:data,
    })
}

export const GetAuditList = function (data) {// 获取评论列表
    return request({
        method: 'GET',
        url: '/API/Comment/GetAuditList',
        params:data,
    })
}

export const Up = function (data) {// 获取评论列表
    return request({
        method: 'GET',
        url: '/API/Comment/Up',
        params:data,
    })
}

export const Del = function (data) {// 获取评论列表
    return request({
        method: 'GET',
        url: '/API/Comment/Del',
        params:data,
    })
}

export const Good = function (data) {// 获取评论列表
    return request({
        method: 'GET',
        url: '/API/Comment/Good',
        params:data,
    })
}



export const Add = function (data) {// 修改帖子
    return request({
        url: "/API/Comment/Add",
        method: 'POST',
        data:Base64.encode(JSON.stringify(data)),
        headers: {
            'Content-Type': 'application/json'
        },
    })
}

export const Update = function (data) {// 修改帖子
    return request({
        url: "/API/Comment/Update",
        method: 'POST',
        data:Base64.encode(JSON.stringify(data)),
        headers: {
            'Content-Type': 'application/json'
        },
    })
}

export const Reply = function (data) {// 修改帖子
    return request({
        url: "/API/Comment/Reply",
        method: 'POST',
        data:Base64.encode(JSON.stringify(data)),
        headers: {
            'Content-Type': 'application/json'
        },
    })
}


export const Audit = function (data) {// 获取评论列表
    return request({
        method: 'GET',
        url: '/API/Comment/Audit',
        params:data,
    })
}

