<template>
    <div class="model">
        <a-spin :spinning="spinning">
            <table class="table">
                <colgroup>
                    <col width='200'>
                    <col width='360'>
                    <col width='200'>
                    <col width='200'>
                </colgroup>
                <thead class="table-thead">
                    <tr>
                        <th>标题</th>
                        <th>内容</th>
                        <th>回复时间</th>
                        <th>操作</th>
                    </tr>
                </thead>
                <tbody class="table-body">
                    <tr v-for="( item  ) in data" :key="item.Id">
                        <td class="center" @click="openDialog(item)">
                            <div class="tdtitle dot2">{{item.Title}}</div>
                        </td>
                        <td class="center" @click="openDialog(item)">
                            <div class="tdtitle dot2">{{item.Content}}</div>
                        </td>
                        <td class="readTime">
                            {{item.CreateTime}}
                        </td>
                        
                        <td class="handle">
                            <div class="handle_inner">
                                <a-popconfirm title="审核通过该帖子？" ok-text="是" cancel-text="否" @confirm="confirmAudit(item)">
                                    <span>审核</span>
                                </a-popconfirm>
                                <a-popconfirm title="删除该帖子？" ok-text="是" cancel-text="否" @confirm="deleteItem(item)">
                                    <span>删除</span>
                                </a-popconfirm>
                                
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <a-empty v-if="data.length == 0"/>
        </a-spin>
        <Pagination
            :pageSize="params.pageSize"
            :total="total"
            @pageChange="pageChange"
            v-if="data.length>0"
            :current="params.pageIndex"
        />
        <!-- 弹窗  -->
        <a-modal
            title="评论"
            :visible="modalVisable"
            okText="审核通过该帖子？"
            @ok="confirmAudit(dialogItem)"
            @cancel="modalVisable = false"
            :width="800"
        >
            <div class="dialog" v-if="dialogItem">
               <h1>
                    <div class="label">标题：</div>
                    <div class="txt">{{dialogItem.Title}}</div>
               </h1>
                <div class="content">
                    <div class="label">内容：</div>
                    <div class="txt" v-html="dialogItem.Content"></div>
                </div>
            </div>
        </a-modal>
    </div>
</template>
<script>
import Pagination from '~/components/common/Pagination'
import { GetList , GetAuditList , Audit} from '@/api/comment'
import { GetMyPostList  } from '@/api/user'
import { Del } from '@/api/forum'
export default {
    props:['keyword','tabId'],
    components:{
        Pagination
    },
    data(){
        return{
            
            data:[],
            params:{
                pageIndex:1,
                pageSize:10,
                key:'',
                isGood:0,
                status:0
            },
            total:0,
            spinning:false,

            modalVisable:false,
            dialogItem:{}
        }
    },
    watch:{
        keyword:{
            handler(newVal,oldVal){
                this.params.key = newVal
            },
            immediate:true
        }
    },
    methods:{
        pageChange(page){
            this.params.pageIndex = page.page
            this.getList()
            document.getElementsByClassName('personal')[0].scrollIntoView({behavior: "auto"})
        },
        onSearch(){
            this.params.pageIndex = 1
            this.getList()
        },
        async getList(){
            this.spinning = true
            const { data , total } = await GetAuditList(this.params)
            console.log(data);
            this.data = data
            this.total = total
            this.spinning = false
        },

        confirmAudit(item){
            this.spinning = true
            Audit({id:item.Id}).then(res => {
                this.$message.success(res.msg)
                this.spinning = false
                this.modalVisable = false
                this.getList()
            }).catch(e => {
                this.spinning = false
            })
        },
        deleteItem(item){
            this.spinning = true
            Del({id:item.Id}).then(res => {
                this.$message.success('删除成功')
                this.spinning = false
                this.getList()
            }).catch(e => {
                this.spinning = false
            })
        },
        openDialog(item){
            console.log(item);
            this.dialogItem = item
            this.modalVisable = true
        },
    },
    created(){
        this.getList()
    }
}
</script>
<style lang="less" scoped>

.tdtitle{
    cursor: pointer;
    // text-align: center;
    &:hover{
        color: #f6a75c;
    }
}
.table .handle_inner span:last-of-type{
    color: #888888;
    &:hover{
        color: #f6a75c;
    }
}
.center{
    text-align: center;
}
.dialog{
    h1{
        margin-bottom: .2rem;
        display: flex;
        .label{
            width: 60px;
            flex-shrink: 0;
            font-size: 20px;
        }
        .txt{
            flex: 1;
            font-size: 20px;
        }
    }
    .content{
        display: flex;
        .label{
            width: 60px;
            flex-shrink: 0;
            font-size: 20px;
        }
        .txt{
            flex: 1;
            max-height: 4rem;
            overflow-y: auto;
            font-size: 16px;
        }
    }
    
}
</style>